import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import { Link } from "gatsby";
import { COLOR, SCREEN, FONT } from "../components/variables";
import InnerWrapper from "../components/inner-wrapper";

const Wrapper = styled.div`
  background-color: ${COLOR.green};
  color: ${COLOR.white};
  min-height: 100vh;
  padding: 70px 0;
  text-align: center;
  @media ${SCREEN.tablet} {
    padding: 250px 0;
  }

  img {
    display: block;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }

  h1 {
    color: ${COLOR.white};
    font-family: ${FONT.heading};
    font-weight: 600;
    font-size: 1.5rem;
    margin: 0 0 25px 0;
    @media ${SCREEN.tablet} {
      font-size: 2.6rem;
      margin: 0 0 30px 0;
    }
  }

  p {
    color: ${COLOR.white};
    font-family: ${FONT.heading};
    font-weight: 600;
    font-size: 1.25rem;
    margin: 25px auto 25px auto;
    display: block;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    @media ${SCREEN.tablet} {
      font-size: 1.65rem;
      margin: 30px 0 0 0;
    }

    a {
      color: ${COLOR.yellow};
      font-weight: 600;
      text-decoration: underline;
    }
  }
`;

const NotFoundPage = () => (
  <Layout is404Page>
    <Seo title="404 Not found" />
    <Wrapper>
      <InnerWrapper>
        <h1>Page not found</h1>

        <img
          src="https://media.giphy.com/media/3o7qE5866bLg4VKabe/giphy.gif"
          alt="Go back"
        />
        <p>
          Run back to <Link to="/">main page</Link>
        </p>
      </InnerWrapper>
    </Wrapper>
  </Layout>
);

export default NotFoundPage;
